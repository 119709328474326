<template lang="pug">
  .order-main(@click="clearNotEnoughErrors")
    el-dialog(v-if="showEditingForbiddenModal" :visible="true" width="420px" @close="showEditingForbiddenModal = false")
      | Это заказ со спецскидкой.
      br
      br
      | Позвоните менеджеру, чтобы изменить его:
      br
      | {{branch.customer_service_phone | phoneNumber(renter.country)}}
    rsv-payment-modal(v-if="showPaymentModal"
      @proceedToPayment="onProceedToPayment"
      :options="paymentPopupOptions"
      @close="showPaymentModal = false"
      :bank-details="bankDetails"
      :rules-url="rulesUrl"
      :renter="renter")
    sbol-payment-modal(v-if="showSbolPaymentModal" @close="showSbolPaymentModal = false" :renter="renter" :customer="customer" :amount="sbolPaymentSum")
    cash-payment-modal(v-if="showCashPaymentModal" @close="showCashPaymentModal = false" :renter="renter" :branch="branch")
    order-confirmation-modal(v-if="showOrderConfirmationModal" :order="order" :branch="branch" :renter="renter" @close="showOrderConfirmationModal = false" )
    .page-heading
      .page-title.page-title_top.order-page-title(v-if="order.id")
        | Заказ {{order.code}}
      .page-title.page-title_top.order-page-title(v-else)
        | {{order.restored_from_code ? `Заказ ${order.restored_from_code}` : 'Новый заказ'}}
      .rent-period-title
        | Период аренды:
      el-popover(v-if="isEditing && (!order.id || (order.state == 'payment_pending' || order.state == 'paid'))"
        popper-class="warning"
        width="200"
        v-model="showRentPeriodError"
        placement="bottom-start"
        trigger="manual")
        p(v-if='order.errors && order.errors.acquisition === "specify"')
          | Укажите период аренды
        p(v-else-if='order.errors && order.errors.earliest_acquisition')
          | Укажите допустимую дату получения:
          br
          | не ранее {{order.errors.earliest_acquisition[0][1]}}
          | {{order.errors.earliest_acquisition[0][0] | monthGenitiveName}}.
        .datepicker-container(slot="reference")
          order-datepicker.cc-datepicker(:value="range"
            @focus="clearErrors"
            @input="syncRange"
            :can-select-urgent-dates="isUserAdmin"
            :is-date-disabled-predicate="isDateDisabled"
            style="align-self: center;")
      div(v-else)
        | {{order.acquisition | date('dd.MM.yy')}} — {{order.returning | date('dd.MM.yy')}}
      .urgency-indicator(v-if='isOrderUrgent')
        | Срочный заказ
      .state.cc-state
        span(v-if='order.needs_confirmation')
          | Ожидает подтверждения
        span(v-else)
          | {{order | stateToWords(renter.country)}}
      .renter-details(v-if="showRenterDetails && branch")
        router-link(:to="getRenterRouteUrl(renter)")
          | {{renter.short_commercial_name}}
        agv-star-rating(:rating="renter.avg_rating" :hide-number="true")
        .cs-telephone
          a(:href="`tel:+7${branch.customer_service_phone}`")
            | {{branch.customer_service_phone | phoneNumber(renter.country)}}
    .line-items
      order-line-item.row(v-for="line_item in lineItemsWithArticles"
        :errorText="errorTextFor(line_item)"
        :key="line_item.article_id"
        :line-item="line_item"
        :renter="calcRenter"
        :original-renter-id="renter.id"
        :branch="calcBranch"
        :is-using-traces="!!order.id"
        :get-article="getArticle"
        :get-article-url="getArticleUrl"
        :photo-url-for="photoUrlFor"
        :available="availableFor(line_item.article)"
        :is-editing="isEditing"
        :errors="order.errors"
        @setQuantity="onSetQuantity"
        @remove="onRemoveArticle"
      )
      .add-item-container.row(v-if="isEditing")
        .cell(style="margin-left: 100px; padding-left: 20px;")
          order-article-select(v-if="selectArticlesInDropdown"
            :renter-id="renter.id"
            :articles="articles"
            :categories="categories"
            @selected="onArticleSelected")
          el-button(v-else type="text" @click="$router.push(addArticleUrl)")
            i.el-icon-pluc
            | Добавить изделие

      order-delivery-by-renter.row(v-if="order.delivery" :order="calcOrder"
        :customer="customer"
        :town="town"
        :branch="calcBranch"
        :renter="calcRenter"
        :get-orders-url="getOrdersUrl"
        :pifakit-service="pifakitService"
        :is-editing="isEditing"
        @remove="onRemoveDelivery"
      )
      order-delivery-by-self.row(v-else
      :line-items="lineItemsWithArticles"
        :branch="branch"
        :renter="renter"
        :is-editing="isEditing"
        @add="onAddDelivery"
      )
      order-arrangement-by-renter.row(v-if="order.arrangement"
        :order="calcOrder"
        :customer="customer"
        :line-items="lineItemsWithArticles"
        :branch="calcBranch"
        :renter="calcRenter"
        :pifakit-service="pifakitService"
        :is-editing="isEditing"
        @remove="onRemoveArrangement")
      order-arrangement-by-self.row(v-else
      :line-items="lineItemsWithArticles"
        :renter="renter"
        :is-editing="isEditing"
        @add="onAddArrangement")
      order-ad-hoc-line-item.row(:item="item" v-for="item in order.ad_hoc_line_items"
        :key="item.id"
        :is-editing="isEditingAdHocLineItems"
        :country-code="renter.country"
        @setName="onSetAdHocLineItemName"
        @setPrice="onSetAdHocLineItemPrice"
        @remove="onRemoveAdHocLineItem")
      .add-item-container.row(v-if="isEditingAdHocLineItems")
        .cell(style="margin-left: 100px; padding-left: 20px;")
          el-button(type="text" @click="addAdHocItem")
            i.el-icon-pluc
            | Добавить позицию
    order-prices(:order="calcOrder" :customer="customer" :branch="calcBranch" :renter="calcRenter" :pifakit-service="pifakitService")
    order-menu(:order="calcOrder"
      :customer="customer"
      :renter="renter"
      :paymentMethods="paymentMethods"
      :is-editing="isEditing"
      :is-embedded="isEmbedded"
      :is-user-admin="isUserAdmin"
      :export-order-url="exportOrderUrl"
      :bank-details="bankDetails"
      :get-customer-bills-url="getCustomerBillsUrl"
      @beginEditing="onBeginEditing"
      @restore="onRestore"
      @place="onPlace"
      @cancel="onCancel"
      @confirmWithoutProcessing="onConfirmWithoutProcessing"
      @confirmAndProcess="onConfirmAndProcess"
      @save="onSave"
      @revert="onRevert"
      @payFromSurplus="onPayFromSurplus"
      @openPaymentModal="onOpenPaymentModal"
    )
</template>

<script>
  import Vue from 'vue'
  import axios from 'axios'

  import IconDownload from 'Shared/sprites/icon-download.svg'
  import OrderLineItem from 'Shared/components/order-line-item'
  import OrderDeliveryBySelf from 'Shared/components/order-delivery-by-self'
  import OrderDeliveryByRenter from 'Shared/components/order-delivery-by-renter'
  import OrderArrangementByRenter from 'Shared/components/order-arrangement-by-renter'
  import OrderArrangementBySelf from 'Shared/components/order-arrangement-by-self'
  import OrderPrices from 'Shared/components/order-prices'
  import OrderMenu from 'Shared/components/order-menu'
  import OrderDatepicker from 'Shared/components/order-datepicker'

  import {getAvailableForPeriodOrTotal} from 'Shared/availabilityUtilites'
  import {getIsUrgent, getPaymentOptions, getTotal} from 'Shared/order'

  import eventBus from 'Shared/eventBus'
  import {proceedToPayment} from 'Shared/payment'
  import RsvPaymentModal from 'RenterSite/components/rsv-payment-modal'
  import SbolPaymentModal from 'Shared/components/sbol-payment-modal'
  import OrderArticleSelect from 'Shared/components/order-article-select'
  import OrderConfirmationModal from 'Shared/components/order-confirmation-modal'
  import CashPaymentModal from 'Shared/components/cash-payment-modal'
  import AgvStarRating from 'Aggregator/components/agv-star-rating'
  import OrderAdHocLineItem from 'Shared/components/order-ad-hoc-line-item'
  import scrollToTopmostError from 'Shared/lib/scrollToTopmostError'
  import allowedPaymentMethodsFor from 'Shared/allowedPaymentMethodsFor'

  const getRentPeriodRange = (order) => {
    if (!(order.acquisition && order.returning)) {
      return null
    }
    return [order.acquisition, order.returning]
  }

  const JUST_RESERVED = 'Только что занято другими пользователями'
  const RESERVED_FOR_PERIOD = 'Частично занято на этот период'

  const EXPLANATIONS_BY_REASON = {
    setRentPeriod: RESERVED_FOR_PERIOD,
    setOrder: JUST_RESERVED,
    saveOrder: JUST_RESERVED,
    navigate: JUST_RESERVED,
  }

  export default {
    name: 'order-main',
    data() {
      return {
        showEditingForbiddenModal: false,
        showPaymentModal: false,
        showSbolPaymentModal: false,
        showCashPaymentModal: false,
        showWireTransferPaymentModal: false,
        showOrderConfirmationModal: false,
        sbolPaymentSum: null,
        paymentPopupOptions: null,
        bankDetails: {}
      }
    },
    props: {
      order: Object,
      renter: Object,
      branch: Object,
      customer: Object,
      articles: Array,
      categories: Array,
      isOrderCurrent: Function,
      isDateDisabledForOrder: Function,
      getTown: Function,
      getArticle: Function,
      getArticleUrl: Function,
      addArticleUrl: String,
      getOrdersUrl: Function,
      getOrderBillUrl: Function,
      getCustomerBillsUrl: Function,
      getLastWireTransferBillDetailsUrl: Function,
      getRenterRouteUrl: Function,
      exportOrderUrl: String,
      rulesUrl: String,
      isEmbedded: Boolean,
      isUserAdmin: Boolean,
      selectArticlesInDropdown: Boolean,
      showRenterDetails: Boolean
    },
    components: {
      OrderAdHocLineItem,
      AgvStarRating,
      CashPaymentModal,
      OrderConfirmationModal,
      OrderArticleSelect,
      SbolPaymentModal,
      RsvPaymentModal,
      OrderDatepicker,
      OrderMenu,
      OrderPrices,
      OrderArrangementBySelf,
      OrderArrangementByRenter,
      OrderDeliveryByRenter,
      OrderDeliveryBySelf,
      OrderLineItem,
      IconDownload
    },
    computed: {
      paymentMethods() {
        return allowedPaymentMethodsFor(this.renter)
      },
      range() {
        return getRentPeriodRange(this.order)
      },
      isEditingAdHocLineItems() {
        return this.isEditing && this.isUserAdmin
      },
      showRentPeriodError: {
        get() {
          if (!this.order.errors) {
            return false
          }
          return !!(this.order.errors.acquisition || this.order.errors.earliest_acquisition)
        },
        set(value) {
        },
      },
      currentOrder() {
        return this.$store.getters['order/getByRenterId'](this.renter.id)
      },
      isEditing() {
        return !this.order.id || this.isOrderCurrent(this.order)
      },
      lineItemsWithArticles() {
        return _.map(
          this.order.line_items,
          li => {
            const foundArticleOrTrace = li.article_trace || this.getArticle(li.article_id, this.renter.id)
            if (!foundArticleOrTrace) {
              throw new Error('no article with id ' + li.article_id)
            }
            return _.extend({article: foundArticleOrTrace}, li)
          }
        )
      },
      calcOrder() {
        let result = _.clone(this.order)
        result.line_items = this.lineItemsWithArticles
        return result
      },
      calcRenter() {
        return this.order.id ?
          _.merge({}, this.order.renter_trace, {id: this.renter.id, country: this.renter.country}) :
          this.renter
      },
      calcBranch() {
        return this.order.id ? this.order.branch_trace : this.branch
      },
      town() {
        return this.getTown(this.order.town_id)
      },
      isOrderUrgent() {
        return getIsUrgent(this.order)
      },
      pifakitService() {
        if (this.order.id) {
          return this.order.pifakit_service_contract?.use_for_customer_price ? this.order.pifakit_service_contract : null
        }

        if (!this.renter.pifakit_service) {
          return null
        }

        return _.merge({renter_profitability: this.renter.pifakit_service_profitability}, this.renter.pifakit_service)
      }
    },
    methods: {
      checkForAhliErrors() {
        const ahlis = this.order.ad_hoc_line_items

        const ahliWithNameError = _.find(ahlis, ahli => ahli.name === '')
        if (ahliWithNameError) {
          this.$store.commit('order/setAdHocLineItemNameError', ahliWithNameError)
        }

        const ahliWithPriceError = _.find(ahlis, ahli => ahli.price === '')
        if (ahliWithPriceError) {
          this.$store.commit('order/setAdHocLineItemPriceError', ahliWithPriceError)
        }

        const firstNegativePriceAhli = _.find(ahlis, ahli => ahli.price < 0)
        const total = getTotal(this.calcOrder, this.customer, this.calcBranch, this.calcRenter, this.pifakitService)
        if (firstNegativePriceAhli && total < 0) {
          this.$store.commit('order/setAdHocLineItemPriceError', firstNegativePriceAhli)
        }

        const hasAhliErrors = !!(ahliWithNameError || ahliWithPriceError)

        if (hasAhliErrors) {
          setTimeout(() => {
            scrollToTopmostError()
          }, 0)
        }

        return hasAhliErrors
      },
      errorTextFor(lineItem) {
        const errors = this.order.errors && this.order.errors['line_items.not_enough']

        if (!errors) {
          return null
        }

        if (_.find(errors, e => e.article_id == lineItem.article_id)) {
          return EXPLANATIONS_BY_REASON[this.$store.state.availability.lastAvailabilityUpdateReason]
        }
        return null
      },
      addAdHocItem() {
        this.$store.commit(
          'order/addAdHocLineItem',
          {
            renterId: this.renter.id
          }
        )
      },
      onArticleSelected() {
        this.$store.commit(
          'order/fixOrder',
          {
            renter: this.renter,
            townId: this.order.town_id,
            getArticle: this.getArticle
          }
        )
      },
      onProceedToPayment(billDetails) {
        const newBillUrl = this.getOrderBillUrl(
          this.renter.id,
          this.order.id,
          billDetails.paymentMethod,
          billDetails.sum,
          this.customer.balance,
          this.bankDetails
        )
        proceedToPayment(
          billDetails.paymentMethod,
          billDetails.sum,
          this.customer.balance,
          newBillUrl,
          eventBus
        ).then(() => {
          this.showPaymentModal = false
        })
      },
      availableFor(article) {
        return getAvailableForPeriodOrTotal(article, this.renter.id, this.$store)
      },
      isDateDisabled(date) {
        return this.isDateDisabledForOrder(date, this.order)
      },
      photoUrlFor(article) {
        if (article.thumbnail_url) {
          return '' + article.thumbnail_url
        } else {
          return '/assets/processing-thumbnail.png'
        }
      },
      syncRange(value) {
        this.$store.dispatch(
          'order/setRentPeriod',
          {
            range: value,
            renterId: this.renter.id
          }
        )
      },
      onAddArrangement() {
        this.$store.commit('order/addArrangement', {renterId: this.renter.id})
      },
      onRemoveArrangement() {
        this.$store.commit('order/removeArrangement', {renterId: this.renter.id})
      },
      onAddDelivery() {
        this.$store.commit('order/addDelivery', {renterId: this.renter.id})
      },
      onRemoveDelivery() {
        this.$store.commit('order/removeDelivery', {renterId: this.renter.id, branch: this.calcBranch})
      },
      onSetQuantity(articleId, quantity) {
        this.$store.commit(
          'order/setArticleQuantity',
          {
            renterId: this.renter.id,
            articleId,
            quantity
          }
        )
      },
      onRemoveArticle(articleId) {
        this.$store.commit(
          'order/removeArticle',
          {
            renterId: this.renter.id,
            articleId,
            clearOrderAfter: !this.isUserAdmin
          }
        )
      },
      onSetAdHocLineItemName(ahli, name) {
        this.$store.commit(
          'order/setAdHocLineItemName',
          {
            renterId: this.renter.id,
            ahli,
            name
          }
        )
      },
      onSetAdHocLineItemPrice(ahli, price) {
        this.$store.commit(
          'order/setAdHocLineItemPrice',
          {
            renterId: this.renter.id,
            ahli,
            price
          }
        )
      },
      onRemoveAdHocLineItem(itemId) {
        this.$store.commit(
          'order/removeAdHocLineItem',
          {
            renterId: this.renter.id,
            itemId
          }
        )
      },
      onCancel() {
        this.$store.dispatch(
          'order/cancel',
          {order: this.order, renterId: this.renter.id}
        ).then(({data: order}) => {
          this.$emit('cancelled', order)
          this.updateCustomerBalance()
        })
      },
      onConfirmAndProcess() {
        this.$store.dispatch(
          'order/confirmAndProcess',
          {order: this.order, renterId: this.renter.id}
        ).then(({data: order}) => {
          this.$emit('confirmed', order)
        })
      },
      onConfirmWithoutProcessing() {
        this.$store.dispatch(
          'order/confirmWithoutProcessing',
          {order: this.order, renterId: this.renter.id}
        ).then(({data: order}) => {
          this.$emit('confirmed', order)
        })
      },
      onBeginEditing() {
        if (!this.isUserAdmin && _.find(this.order.ad_hoc_line_items, ahli => ahli.price < 0)) {
          this.showEditingForbiddenModal = true
          return
        }

        this.$store.dispatch('order/beginEditing', {order: this.order, renterId: this.renter.id})
      },
      onRestore() {
        this.$store.commit('order/setCurrent', {
          order: {
            acquisition: this.order.acquisition,
            returning: this.order.returning,
            line_items: _.map(this.order.line_items, li => _.pick(li, ['quantity', 'article_id'])),
            delivery: _.clone(this.order.delivery),
            arrangement: _.clone(this.order.arrangement),
            restored_order_id: this.order.id,
            restored_from_code: this.order.code,
            town_id: this.order.town_id
          },
          renterId: this.renter.id
        })
        this.$emit('restored', this.$store.getters['order/getByRenterId'](this.renter.id))
      },
      onPlace() {
        if (this.customer.id) {
          if (this.checkForAhliErrors()) {
            return
          }

          this.$store.dispatch('order/place', {
            order: this.order,
            renterId: this.renter.id
          }).then((placedOrder) => {
            this.$store.commit('order/clearCurrent', {townId: this.town.id, renterId: this.renter.id})
            this.$emit('placed', placedOrder.id)
            this.updateCustomerBalance()
          }, (errors) => {
            this.$emit('validationErrors', errors)
          })
        } else {
          eventBus.$emit('proceedToLogin')
        }
      },
      onSave() {
        if (this.checkForAhliErrors()) {
          return
        }

        this.$store.dispatch(
          'order/save',
          {order: this.order, renterId: this.renter.id}
        ).then((order) => {
          this.$emit('saved')
          this.$emit('updateCustomerBalance')

          if (!this.isUserAdmin && order.needs_confirmation) {
            this.showOrderConfirmationModal = true
          }
        }, (errors) => {
          this.$store.dispatch('order/setErrors', {errors, renterId: this.renter.id})
          this.$emit('validationErrors', errors)
        })
      },
      onRevert() {
        this.$store.dispatch('order/revert', {renterId: this.renter.id})
        this.$emit('finishedEditing')
        // TODO: удалить, если action finishEditing работает нормально
      },
      onPayFromSurplus() {
        this.$store.dispatch(
          'order/payFromSurplus',
          {order: this.order, renterId: this.renter.id}
        ).then((order) => {
          this.order.payment_due = order.payment_due
          this.order.required_payment = order.required_payment
          this.order.paid = order.paid
          this.order.state = order.state
        }).then(this.updateCustomerBalance)
      },
      onOpenPaymentModal() {
        this.doShowPaymentModal()
      },
      onOrderChange() {
        if (this.order.state === 'payment_pending' && this.order.is_just_placed && !this.order.paid && !this.isEmbedded) {
          if (this.order.needs_confirmation) {
            this.showOrderConfirmationModal = true
          } else {
            this.doShowPaymentModal()
          }
        }
      },
      doShowPaymentModal() {
        this.paymentPopupOptions = getPaymentOptions(
          this.order,
          this.customer.balance,
          this.renter,
          this.rulesUrl
        )

        axios.get(this.getLastWireTransferBillDetailsUrl(this.renter.id)).then(({data: details}) => {
          this.bankDetails = details || {}
          this.showPaymentModal = true
        })
      },
      clearErrors() {
        this.$store.commit('order/clearErrors', {renterId: this.renter.id})
      },
      clearNotEnoughErrors() {
        if (this.order.errors && this.order.errors['line_items.not_enough']) {
          this.$store.commit('order/clearNotEnoughErrors', {renterId: this.renter.id})
        }
      },
      updateCustomerBalance() {
        this.$emit('updateCustomerBalance')
      }
    },
    created() {
      eventBus.$on('openSbolPaymentModal', (sum) => {
        this.sbolPaymentSum = sum
        this.showPaymentModal = false
        this.showSbolPaymentModal = true
      })
      eventBus.$on('openCashPaymentModal', (sum) => {
        this.showCashPaymentModal = true
      })
    },
    watch: {
      order: [{
        handler: 'onOrderChange',
        immediate: true
      }]
    }
  }
</script>

<style scoped>
  .page-heading {
    margin-bottom: 20px;
  }

  .state {
    color: grey;
    flex-grow: 1;
    text-align: right;
  }

  .order-main >>> .row {
    padding-top: 30px;
    padding-bottom: 30px;
    min-height: 70px;
    border-bottom: 1px solid #e8e8e8;
  }

  .add-item-container {
    height: 135px;
    display: flex;
    align-items: center;
  }

  .urgency-indicator {
    padding-left: 20px;
    font-size: 75%;
    color: #4a4a4a;
  }

  .renter-details {
    display: flex;
    font-size: 14px;
    width: 100%;
    height: 30px;
    margin-top: 20px;
  }

  .renter-details > div {
    margin-left: 10px;
  }

  .cs-telephone a {
    color: black !important;
  }
</style>

<style>
  .el-popover.error {
    background: #ffc8c8;
    color: #4a4a4a;
  }

  .el-popover.error[x-placement^=bottom] .popper__arrow::after {
    border-bottom-color: #ffc8c8;
  }

  .el-popover.error[x-placement^=top] .popper__arrow::after {
    border-top-color: #ffc8c8;
  }

  .el-popover.error[x-placement^=left] .popper__arrow::after {
    border-left-color: #ffc8c8;
  }

  .el-popover.error[x-placement^=right] .popper__arrow::after {
    border-right-color: #ffc8c8;
  }

  .el-popover.warning {
    background: #ffb080;
    color: white;
  }

  .el-popover.warning[x-placement^=bottom] .popper__arrow::after {
    border-bottom-color: #ffb080;
  }

  .el-popover.warning[x-placement^=top] .popper__arrow::after {
    border-top-color: #ffb080;
  }

  .el-popover.warning[x-placement^=left] .popper__arrow::after {
    border-left-color: #ffb080;
  }

  .el-popover.warning[x-placement^=right] .popper__arrow::after {
    border-right-color: #ffb080;
  }

  .order-main {
    background: white;
  }

  .order-main .total-price-cell {
    font-size: 18px;
    width: 120px;
    text-align: right;
  }

  .rent-period-title {
    margin-right: 20px;
  }

  .order-service-row {
    min-height: 190px;
    display: flex;
    align-items: baseline;
  }

  .order-service-row > div {
    padding-left: 20px;
  }

  .order-service-row .name-cell {
    width: 200px;
    min-width: 200px;
    margin-left: 100px;
    margin-right: 20px;
  }

  .order-service-row .service-name {
    font-weight: bold;
  }

  .order-service-row .service-clarification {
    margin-top: 5px;
    font-size: 13px;
    line-height: 1.5em;
  }

  .order-service-row .label-mandatory {
    font-size: 14px;
    color: grey;
    position: relative;
    margin-top: 10px;
    cursor: pointer;
  }

  .order-service-row .explanation {
    text-align: left;
    padding: 10px;
  }

  .order-service-row .service-description-cell {
    flex-grow: 2;
  }

  .order-service-row .service-description {
    font-size: 13px;
    line-height: 1.5em;
  }

  .order-service-row .service-description__title {
    font-size: 14px;
    margin-bottom: 10px;
  }

  ul.articles-with-mandatory-services {
    padding: 0 5px;
    list-style-type: none;
    margin-top: 20px;
  }

  ul.articles-with-mandatory-services li {
    margin-left: -3px;
  }

  ul.articles-with-mandatory-services li:before {
    content: "—";
    margin-right: 10px;
    text-indent: -20px;
  }

  .order-menu {
    margin-top: 40px;
  }
</style>
