<template lang="pug">
  .content
    .banners-container(v-if="firstBanner")
      el-carousel.banner-carousel(v-if="frontPageBanners.length > 1"
        indicator-position="outside"
        height="320"
        :interval="4000"
        trigger="click"
        :class="{'banner-carousel-taller': allowTallerBanners}")
        el-carousel-item(v-for="banner in frontPageBanners" :key="banner.id")
          a(:href='banner.url' target="_blank")
            img.front-page-banner(:src="banner.image.url" style="")
      a(v-else :href='firstBanner.url' target="_blank")
        img.front-page-banner(:src="firstBanner.image.url")
    .title
      | {{renter.short_commercial_name_cases[0]}} предлагает {{renter.rent_object_cases[3]}} в аренду
    .catalog
      agv-kind-list(:kinds="categoriesForList")
    div(v-if="!$store.state.featureFlags.disable_new_articles && recentArticles.length > 0")
      .title
        | Новинки этого года
      .catalog
        agv-article(v-for="article in recentArticles"
          :key="article.id"
          :town-id="town.id"
          :renter="renter"
          :article="article",
          :show-discount="showDiscount"
          is-in-wide-view)
</template>

<script>
import _ from 'lodash'
import dayjs from 'dayjs'

import AgvKindList from 'Aggregator/components/agv-kind-list'
import AgvArticle from 'Aggregator/components/agv-article'

import {getCurrentOrder} from 'RenterSite/lib/order'

export default {
  name: 'page-rsv-front',
  data() {
    return {}
  },
  components: {AgvArticle, AgvKindList},
  computed: {
    showDiscount() {
      return this.$store.getters.showDiscount
    },
    order() {
      return getCurrentOrder()
    },
    allowTallerBanners() {
      return this.$store.state.featureFlags.taller_front_page_banners
    },
    town() {
      return this.$store.state.town.current
    },
    frontPageBanners() {
      return _.filter(
          this.$store.state.frontPageBanners,
          b => !b.town_ids || _.includes(b.town_ids, this.$store.state.town.current.id)
      )
    },
    firstBanner() {
      return _.first(this.frontPageBanners)
    },
    categoriesOfRelevantArticles() {
      return _.filter(this.$store.state.categories, c => _.find(this.$store.getters.relevantArticles, a => a.category_id === c.id))
    },
    categoriesForList() {
      return _.map(
        _.orderBy(
          _.filter(this.categoriesOfRelevantArticles, c => {
            const hasCoverArticle = c.cover_article_id && this.$store.getters['articles/getByRenterIdAndArticleId'](this.$store.state.renter.id, c.cover_article_id);
            const hasRelevantArticle = _.some(this.$store.getters.relevantArticles, a => a.category_id === c.id);

            return hasCoverArticle || hasRelevantArticle;
          }),
          'position'
        ),
        c => {
          const article = c.cover_article_id ?
            this.$store.getters['articles/getByRenterIdAndArticleId'](
              this.$store.state.renter.id,
              c.cover_article_id
            )
            :
            _.first(
              _.orderBy(
                _.filter(
                  this.$store.getters.relevantArticles,
                  a => a.category_id === c.id
                ),
                'position_in_category'
              )
            )
          return {
            id: c.id,
            name: c.name,
            url: `/catalogue/category/${c.id}`,
            image_url: `${article.thumbnail_url}`
          }
        }
      )
    },
    recentArticles() {
      const yearAgo = dayjs().subtract(1, 'year')
      return _.orderBy(
          _.filter(
              this.$store.getters.relevantArticles,
              a => dayjs(a.created_at).isAfter(yearAgo) && a.are_all_components_offered
          ),
          a => a.created_at,
          'desc'
      )
    },
    renter() {
      return this.$store.state.renter
    }
  }
}
</script>

<style scoped>
.banner-carousel >>> .el-carousel__container {
  height: calc((100vw - 120px) / 4);
  min-height: calc((1100px - 120px) / 4);
  max-height: calc((1400px - 120px) / 4);
}

.banner-carousel-taller >>> .el-carousel__container {
  height: calc((100vw - 120px) / 2);
  min-height: calc((1100px - 120px) / 2);
  max-height: calc((1400px - 120px) / 2);
}

.front-page-banner {
  width: 100%;
}

.catalog {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 42px;
}

.banners-container {
  margin-bottom: 42px;
}

.catalog .ag-article {
  flex-basis: 25%;
}

.title {
  line-height: 42px;
  font-size: 24px;
  display: block;
  margin-bottom: 30px;
}
</style>
