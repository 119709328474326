.content {
  margin-top: 47px;
  margin-bottom: 60px;
}
.arrow {
  margin: 0 3px;
}
.page-heading {
  display: flex;
  align-items: baseline;
  padding-bottom: 17px;
  border-bottom: 1px solid #e7e7e7;
  margin-bottom: 48px;
  flex-wrap: wrap;
  justify-content: space-between;
}
.page-title {
  font-weight: bold;
  letter-spacing: 0;
}
.page-title_top {
  margin-right: 30px;
  font-size: 28px;
  white-space: nowrap;
}
.tools {
  flex-grow: 1;
  margin-top: 10px;
}
.item-count {
  width: 185px;
  font-size: 14px;
  color: #949494;
  white-space: nowrap;
}
.filter-item.router-link-active {
  border: 1px solid #7799e4;
  -moz-box-shadow: 0 0 0 1px #7799e4;
  -webkit-box-shadow: 0 0 0 1px #7799e4;
  box-shadow: 0 0 0 1px #7799e4;
}
.filter-block {
  width: 185px;
  margin-bottom: 32px;
}
.filter-block-title {
  margin-bottom: 10px;
  font-size: 17px;
  color: #000;
  line-height: 19px;
}
.el-dialog__header {
  padding: 50px 50px 10px;
}
.el-dialog__header .el-dialog__title {
  font-size: 28px;
  line-height: 1.5em;
}
.el-dialog__body {
  padding: 20px 50px 50px;
}
.el-dialog__body p {
  margin-bottom: 10px;
}
.el-dialog__headerbtn .el-dialog__close {
  font-size: 28px;
}
.no-wrap {
  white-space: nowrap;
}
.table {
  width: 100%;
  margin-top: 41px;
}
.table td {
  height: 58px;
}
.table th, .table td {
  padding-bottom: 17px;
  padding-right: 20px;
  vertical-align: bottom;
}
.table th {
  font-size: 14px;
  color: #000;
  text-align: left;
  padding: 0;
  padding-bottom: 17px;
  font-weight: normal;
}
.table th.cell-4 {
  text-align: right;
}
.table td {
  font-size: 14px;
  color: #000;
  padding: 0;
  padding-bottom: 17px;
  border-top: 1px solid #e7e7e7;
  cursor: pointer;
  white-space: nowrap;
}
::-webkit-input-placeholder {
  color: #c1c1c1;
}
