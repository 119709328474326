html, body, div, span, h1, h2, h3, h4, h5, h6, p, em, img, strong, sub, sup, b, u, i, dl, dt, dd, ol, ul, li, fieldset, form, label, table, tbody, tfoot, thead, tr, th, td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: baseline;
  background: transparent;
  box-sizing: border-box;
}
a {
  margin: 0;
  padding: 0;
  vertical-align: baseline;
  background: transparent;
  text-decoration: none;
  outline: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
td, td img {
  vertical-align: top;
}
input, select, button, textarea {
  margin: 0;
  font-size: 100%;
}
input[type="checkbox"] {
  vertical-align: bottom;
}
input[type="radio"] {
  vertical-align: text-bottom;
}
sub {
  vertical-align: sub;
  font-size: smaller;
}
sup {
  vertical-align: super;
  font-size: smaller;
}
input[type="text"], input[type="submit"], textarea {
  -webkit-appearance: none;
}
