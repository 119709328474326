<template lang="pug">
  el-popover(placement="bottom-start"
  popper-class="order-article-select-popover"
  width="620"
  v-model="visible"
  trigger="click")
    el-container
      el-container.article-list-container
        table
          tr(v-for='article in filteredAndOrderedArticles' @click='select(article)')
            td.col-thumbnail
              .thumbnail
                img(:src='`${article.thumbnail_url}`')
            td.col-name
              .name
                | {{article.name}}
            td.col-available
              | {{availableFor(article)}} шт.
      el-aside.categories-container(width="200px")
        el-input(v-model="search" @input="clearCategorySelection" placeholder="Поиск" size="small" :clearable="true" prefix-icon="el-icon-search")
        .category-rows
          .category-row(v-for="category in categories")
            el-button.category-btn(@click="setCategoryId(category.id)" type="text" :class="{'category-btn_selected': categoryId === category.id}")
              | {{category.name}}
    el-button(slot="reference"
    type="text")
      | Добавить изделие
</template>

<script>
  import {getAvailableForPeriodOrTotal} from 'Shared/availabilityUtilites'

  export default {
    name: "order-article-select",
    props: {
      renterId: Number,
      articles: Array,
      categories: Array,
    },
    data() {
      return {
        search: '',
        categoryId: null,
        visible: false
      }
    },
    computed: {
      articlesFilteredByCategorySearchOrCategory() {
        if (this.search.length > 2) {
          return _.filter(this.articles, (article) => {
              let name = article.name.toLowerCase()
              return _.every(this.search.toLowerCase().split(/ +/), w => name.indexOf(w) !== -1)
            }
          )
        }

        if (this.categoryId) {
          return _.filter(this.articles, (article) => article.category_id === this.categoryId)
        }

        return this.articles
      },
      filteredArticles() {
        return _.filter(this.articlesFilteredByCategorySearchOrCategory, a => a.total_available > 0 && a.is_offered)
      },
      filteredAndOrderedArticles() {
        const result = _.orderBy(this.filteredArticles, ['category_position', 'position_in_category'])
        if (this.categoryId) {
          return result
        }

        return _.take(result, 50)
      }
    },
    methods: {
      availableFor(article) {
        return getAvailableForPeriodOrTotal(article, this.renterId, this.$store)
      },
      select(article) {
        if (article.type === 'Product') {
          this.$store.commit(
              'order/addArticle',
              {
                articleId: article.id,
                renterId: this.renterId
              }
          )
        } else {
          this.$store.commit(
              'order/addArticlesQuantity',
              {
                componentsWithQuantity: article.components_with_quantity,
                quantity: 1,
                renterId: this.renterId
              })
        }
        this.$emit('selected')
        this.visible = false
      },
      clearCategorySelection() {
        this.categoryId = null
      },
      setCategoryId(id) {
        this.categoryId = id
        this.search = ''
      }
    },
    created() {
      this.categoryId = _.get(this.categories[0], 'id')
    }
  }
</script>

<style scoped>
  tr {
    cursor: pointer;
  }

  tr:hover {
    background-color: #FFFFED;
  }

  td {
    padding: 10px;
    padding-bottom: 2px;
    vertical-align: center;
  }

  .el-aside {
    padding-left: 20px;
  }

  .col-last {
    padding-right: 25px !important;
  }

  .category-rows {
    margin-top: 20px;
  }

  .col-thumbnail {
    width: 45px;
    padding-left: 5px !important;
    padding-top: 6px !important;
    padding-right: 12px;
  }

  .name {
    height: 31px;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .col-name {
    width: 225px;
    max-width: 225px;
  }

  .col-available {
    padding-right: 10px;
    padding-left: 10px;
  }

  .thumbnail {
    display: block;
    height: 30px;
    position: relative;
  }

  .thumbnail > img {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    max-height: 30px;
    max-width: 30px;

    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%)
  }

  .article-list-container, .categories-container {
    max-height: 400px;
    overflow-y: scroll;
  }

  .category-btn {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .category-btn_selected {
    font-weight: bold;
  }
</style>
