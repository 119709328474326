<template lang="pug">
  .content(v-if='photos.length > 0')
    .page-heading
      h1.page-title.page-title_top
        | {{title | capitalize}}
      .item-count
        | Показано {{filteredPhotos.length | decline(['фотография', 'фотографии', 'фотографий'])}}
    .gallery-container
      .gallery
        .photo(v-for="photo in orderedPhotos")
          router-link(:to="`/photo/${photo.id}`")
            img.photo_image(:src="`${photo.preview_url}`")
      .sidebar-wrapper.noselect
        .filter-block
          .filter-block-title Категория
          agv-tag-ul.categories(:tags="orderedCategories"
            :active-tag-ids='orderedCategoryIds'
            :checked-tag-ids='checkedCategoryIds'
            :show-thumbnails="false"
            @toggle='toggleCategory')
</template>

<script>
  import axios from 'axios'
  import _ from 'lodash'

  import Vue from 'vue'

  import AgvTagUl from 'Aggregator/components/agv-tag-ul'
  import TruncatedTitle from 'RenterSite/shared/TruncatedTitle'

  export default {
    name: 'page-rsv-gallery-photos-list',
    components: {AgvTagUl},
    data() {
      return {
        photos: [],
        checkedCategoryIds: {}
      }
    },
    computed: {
      metaTitle() {
        return new TruncatedTitle(`Фотографии декора в аренду: ${this.$store.state.category_names}`).toString()
      },
      title() {
        return 'Галерея'
      },
      path() {
        return '/api/gallery_photos.json'
      },
      filteredPhotos() {
        if (!this.anyCheckedCategories) {
          return this.photos
        }
        return _.filter(this.photos, p => _.find(p.all_category_ids, id => this.checkedCategoryIds[id]))
      },
      orderedPhotos() {
        return _.orderBy(this.filteredPhotos, 'position', 'desc')
      },
      anyCheckedCategories() {
        return _.values(this.checkedCategoryIds).some(v => v)
      },
      categories() {
        const categoryIds = _.uniq(_.flatten(_.map(this.photos, p => p.all_category_ids)))
        return _.filter(this.$store.state.categories, c => _.includes(categoryIds, parseInt(c.id)))
      },
      orderedCategories() {
        return _.orderBy(this.categories, 'position')
      },
      orderedCategoryIds() {
        return _.fromPairs(
          _.map(this.orderedCategories, c => [c.id, true])
        )
      }
    },
    metaInfo() {
      return {
        title: this.metaTitle
      }
    },
    methods: {
      fetchData() {
        if (this.path) {
          axios.get(this.path)
            .then(({data: photos}) => {
              this.photos = photos
              this.$emit('loaded')
            })
        }
      },
      toggleCategory(category) {
        Vue.set(this.checkedCategoryIds, category.id, !this.checkedCategoryIds[category.id])
      }
    },
    created() {
      this.fetchData()
    }
  }
</script>

<style scoped>
  .page-heading {
    justify-content: space-between;
  }

  .gallery-container {
    display: flex;
    justify-content: space-between;
  }

  .gallery {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    height: fit-content;
  }

  .photo {
    flex-basis: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
  }

  .photo_image_container {
    overflow: hidden;
    /*height: 241px;*/
    position: relative;
    cursor: default;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .photo_image_container img {
    cursor: pointer;
  }

  .sidebar-wrapper {
    position: relative;
    margin-left: 20px;
  }

  .sidebar-wrapper >>> .btn-filter-remove {
    position: absolute;
    top: -29px;
    right: 0;
  }
</style>
