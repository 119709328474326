.color[data-color="white"] {
    background-color: #f2f2f2;
}
.color[data-color="black"] {
    background-color: #303133;
}
.color[data-color="grey"] {
    background-color: #808281;
}
.color[data-color="red"] {
    background-color: #c51512;
}
.color[data-color="orange"] {
    background-color: #f58b00;
}
.color[data-color="beige"] {
    background-color: #ab6c3f;
}
.color[data-color="yellow"] {
    background-color: #f5dc00;
}
.color[data-color="green"] {
    background-color: #319451;
}
.color[data-color="turquois"] {
    background-color: #5bb3a8;
}
.color[data-color="blue"] {
    background-color: #2f73c6;
}
.color[data-color="violet"] {
    background-color: #974eb7;
}
.color[data-color="pink"] {
    background-color: #f282b8;
}
.color[data-color="transparent"] {
    background-image: url("../../../assets/images/icons/transparent-color.png");
}
.color[data-color="not-important"] {
    background-image: url("../../../assets/images/not-important.png");
    border: 1px solid #dbdbdb;
}
.color[data-color="multicolored"] {
    background-image: url("../../../assets/images/icons/multicolored.png");
}
.color[data-color="gold"] {
    background-image: url("../../../assets/images/icons/gold.png");
}
.color[data-color="silver"] {
    background-image: url("../../../assets/images/icons/silver.png");
}
