.root .vue-back-to-top {
  right: auto !important;
  left: 30px !important;
  bottom: 30px !important;
}
.root .vue-back-to-top .default {
  background-color: white;
  border: 1px solid #aaa;
  opacity: 0.8;
  width: 90px;
  line-height: 35px;
}
.root .vue-back-to-top .default span {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  color: #4a4a4a;
}
.root .vue-back-to-top .default span:before {
  display: inline-block;
  position: relative;
  content: '↑';
  margin-right: 5px;
}
