body, input, select, button, textarea {
  font-family: 'Inter', sans-serif;
}
body {
  position: relative;
  text-align: left;
  color: #000;
  background: #fff;
}
label, input[type="button"], input[type="submit"], button {
  cursor: pointer;
}
input {
  border: none;
}
a, .link-btn, button {
  color: #0074d9;
  cursor: pointer;
  text-decoration: none;
}
a:hover, .link-btn:hover, button:hover {
  color: #008cff;
}
.el-checkbox.el-checkbox_error .el-checkbox__inner {
  border-color: #d0021b !important;
}
.el-checkbox__input.is-checked .el-checkbox.el-checkbox_error .el-checkbox__inner {
  background-color: #d0021b !important;
}
.el-checkbox.el-checkbox_error .el-checkbox__label {
  color: #d0021b !important;
}
.el-picker-panel__content {
  max-height: 285px !important;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
.error-report {
  position: absolute;
  width: 200px;
  padding: 10px 20px;
  background: #b3ddff;
  opacity: 0.9;
  top: 20px;
  right: 20px;
  border-radius: 3px;
  font-size: 13px;
  font-family: helveticaneuecyrroman, sans-serif;
  z-index: 100;
}
.el-popover.order-article-select-popover {
  max-height: 421px;
  padding: 20px;
  margin-top: -40px;
}
.el-popover {
  text-align: left !important;
  padding: 20px !important;
  word-break: keep-all !important;
}
.el-popover p {
  margin-bottom: 5px;
}
.error .el-input__inner {
  color: #d0021b !important;
}
.error .el-input__inner::-webkit-input-placeholder {
  color: #E88290 !important;
}
.filter-block {
  user-select: none;
}
